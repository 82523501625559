<template>
  <div class="login">
    <Header>
      <div slot="mens" class="loginText">
        <div>欢迎登录</div>
      </div>
    </Header>
    <div class="box">
      <img src="@/assets/images/ssCode.png" class="ssCode" alt="" />
      <img
        src="@/assets/images/liteCode.png"
        class="liteCode"
        alt=""
        @click="codeClick"
      />

      <div v-if="codeBoo">
        <div class="signText">短信登录</div>
        <Form
          ref="formInline"
          :model="formInline"
          :rules="ruleInline"
          class="Form"
          inline
        >
          <FormItem prop="mobile">
            <Input
              type="text"
              v-model="formInline.mobile"
              placeholder="手机号码"
              class="input"
            >
              <!-- <Icon type="md-phone-portrait" size="24" slot="prepend"></Icon> -->
            </Input>
            <div class="prepend"></div>
            <img src="@/assets/images/phone.png" alt="" class="iconImg" />
          </FormItem>
          <FormItem prop="code">
            <Input
              type="text"
              v-model="formInline.code"
              placeholder="验证码"
              class="input"
              @keyup.enter.native="handleSubmit('formInline')"
            >
              <!-- <Icon type="ios-lock-outline" slot="prepend"></Icon> -->
            </Input>
            <span class="codeNum" v-if="!disabled" @click="codeHo">{{
              btnText
            }}</span>
            <span class="codeNumOne" v-else>{{ btnText }}</span>
            <div class="prepend"></div>
            <img src="@/assets/images/codeNum.png" alt="" class="iconImg1" />
          </FormItem>
          <FormItem>
            <Button
              type="primary"
              @click="handleSubmit('formInline')"
              class="button"
              >登录</Button
            >
          </FormItem>
        </Form>
      </div>
      <div id="login_container" v-show="!codeBoo"></div>
    </div>
    <!-- <div id="login" class="login">
      <iframe class="iframe"></iframe>
    </div> -->
  </div>
</template>

<script>
import api from "@/util/api";
import Header from "@/components/Header";
// let redirectUrl;
export default {
  name: "login",
  components: { Header },
  data() {
    return {
      formInline: {
        mobile: "",
        code: "",
      },
      codeBoo: true,
      ruleInline: {
        mobile: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号码格式不正确",
            trigger: "blur",
          },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      disabled: false,
      btnText: "获取验证码",
    };
  },
  mounted() {},
  created() {
    if (this.getUrlCode("code")) {
      const code = this.getUrlCode("code");
      api.userWxlogin({ code: code }, (res) => {
        let preRoute = sessionStorage.getItem("preRoute") || "/";
        this.$router.push({ path: preRoute });
        sessionStorage.setItem("token", res.data.token);
        sessionStorage.setItem("userInfo", JSON.stringify(res.data));
      });
    }
  },
  // beforeRouteEnter(to, from, next) {
  //           if (to.query && to.query.redirect) {
  //               redirectUrl = to.query.redirect
  //           } else {
  //               redirectUrl = from.fullPath
  //           }
  //           next(); // 这个不写的话无法显示login页面
  //      },
  methods: {
    getUrlCode(name) {
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
    // codeClick() {
    //   this.codeBoo = !this.codeBoo;
    //   var obj = new WxLogin({
    //     self_redirect: false,
    //     id: "login_container",
    //     appid: "wx6f0ed51a6c5feb31",
    //     scope: "snsapi_login",
    //     response_type: "code",
    //     redirect_uri: encodeURIComponent("http://ceshi.mubaiwang.com/#/login"),
    //     state: "STATE#wechat_redirect",
    //     style: "black",
    //     href: "data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHsNCiAgICB0ZXh0LWFsaWduOiBjZW50ZXI7DQogICAgZm9udC1zaXplOiAxNnB4Ow0KICAgIG1hcmdpbi10b3A6IDYwcHg7DQp9DQouaW1wb3dlckJveCAucXJjb2RlIHsNCiAgICB3aWR0aDogMTYwcHg7DQogICAgbWFyZ2luLXRvcDogMTVweDsNCiAgICBib3JkZXI6IDFweCBzb2xpZCAjRTJFMkUyOw0KfQ0KLmltcG93ZXJCb3ggew0KICAgIGxpbmUtaGVpZ2h0OiAxLjY7DQogICAgcG9zaXRpb246IHJlbGF0aXZlOw0KICAgIHdpZHRoOiAxMDAlOw0KICAgIHotaW5kZXg6IDE7DQogICAgdGV4dC1hbGlnbjogY2VudGVyOw0KICAgIG1hcmdpbi1sZWZ0OiA0NHB4Ow0KfQ==",
    //   });
    // },
    codeClick() {
      // var obj = new WxLogin({
      //   id: "login",
      //   appid: "wx6f0ed51a6c5feb31",
      //   scope: "snsapi_login",
      //   redirect_uri: encodeURIComponent("http://ceshi.mubaiwang.com"),
      //   state: "STATE#wechat_redirect",
      //   // style: "",
      //   // href: "",
      // });
      // console.log(111);
      let APPID = "wx6f0ed51a6c5feb31";
      let REDIRECT_URI = encodeURIComponent(
        "http://shopping.mubaiwang.com/#/login"
      );
      location.href = `https://open.weixin.qq.com/connect/qrconnect?appid=${APPID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=snsapi_login&state=STATE#wechat_redirect `;
      // console.log(location.search);
      // let search = location.search.slice(1);
      // let arr = search.split("&");
      // let result = {};
      // arr.forEach(function (item) {
      //   let itemArr = item.split("=");
      //   result[itemArr[0]] = itemArr[1];
      // });

      // if (result.code) {
      //   //   //调用后端接口
      //   console.log("result.code", result.code);
      //   api.userWxlogin({ code: "091r1mll2igGc642bRml26rD9v1r1mla" }, (res) => {
      //     console.log("res", res);
      //     this.$router.push({ path: "/" });
      //     sessionStorage.setItem("token", res.data.token);
      //     sessionStorage.setItem("userInfo", JSON.stringify(res.data));
      //   });
      // }
    },
    codeHo() {
      if (!/^1[3|4|5|8|7][0-9]\d{4,8}$/.test(this.formInline.mobile)) {
        this.$Message.error("手机号码有误，请重填");
        return false;
      }
      api.userSendMessage({ phone: this.formInline.mobile }, (res) => {
        this.disabled = true;
        this.btnText = "请稍候...";
        setTimeout(() => {
          this.doLoop(60);
        }, 500);
      });
    },
    doLoop: function (seconds) {
      this.btnText = seconds + "s后获取";
      let countdown = setInterval(() => {
        if (seconds > 0) {
          this.btnText = seconds + "s后获取";
          --seconds;
        } else {
          this.btnText = "获取验证码";
          this.disabled = false;
          clearInterval(countdown);
        }
      }, 1000);
    },
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          api.userloginMessage(this.formInline, (res) => {
            sessionStorage.setItem("token", res.data.token);
            sessionStorage.setItem("userInfo", JSON.stringify(res.data));
            let preRoute = sessionStorage.getItem("preRoute") || "/";
            this.$router.push({ path:preRoute});
            this.$Message.success("登录成功!");
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.impowerBox .qrcode {
  width: 200px;
}
.impowerBox .title {
  display: none;
}
.impowerBox .info {
  width: 200px;
}
.status_icon {
  display: none;
}
.impowerBox .status {
  text-align: center;
}
.login {
  font-family: PingFangSC-Regular, PingFang SC;
  width: 100%;
  height: 600px;
  background: url("../../../assets/images/login.jpg") center no-repeat;
  background-size: cover;
  position: relative;

  .box {
    width: 400px;
    height: 345px;
    background: #ffffff;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    position: absolute;
    top: 139px;
    left: 56%;
  }
  .liteCode {
    width: 154px;
    height: 154px;
    // width:400px;
    // height:345px;
    position: absolute;
    top: -55px;
    right: -59px;
    z-index: 9;
    cursor: pointer;
  }
  .ssCode {
    position: absolute;
    top: 20px;
    right: 87px;
    width: 116px;
    height: 30px;
    z-index: 9;
    cursor: pointer;
  }
}
.loginText {
  width: 940px;
  height: 33px;
  font-size: 24px;
  font-weight: 400;
  color: #1a1a1a;
  line-height: 50px;
  letter-spacing: 1px;
}
.signText {
  width: 76px;
  height: 25px;
  font-size: 18px;
  font-weight: 400;
  color: #1a1a1a;
  line-height: 25px;
  letter-spacing: 1px;
  margin: 70px 0 0 40px;
}
.signText::before {
  content: "";
  display: block;
  position: relative;
  top: 30px;
  width: 76px;
  height: 4px;
  background: #1a1a1a;
  border-radius: 2px;
}
// .Form .ivu-input-group-append .ivu-input-group-prepend {
//     padding: 8px 12px !important;
//     font-size: inherit;
//     font-weight: 400;
//     line-height: 1;
//     color: #515a6e;
//     text-align: center;
//     background-color: rgba(216, 216, 216, 1);
//     border: 1px solid #dcdee2;
//     border-radius: 4px;
//     }
.ivu-input-group-append,
.ivu-input-group-prepend {
  padding: 4px 12px;
  font-size: inherit;
  font-weight: 400;
  line-height: 1;
  color: #515a6e;
  text-align: center;
  background-color: #f8f8f9;
  border: 1px solid #dcdee2;
  border-radius: 4px;
}
.Form {
  width: 320px;
  height: 176px;
  margin: 28px auto 0;

  /deep/ .ivu-input {
    display: inline-block;
    width: 100%;
    height: 40px;
    line-height: 1.5;
    padding: 4px 7px;
    font-size: 14px;
    border: 1px solid #dcdee2;
    border-radius: 4px;
    color: #515a6e;
    background-color: #fff;
    background-image: none;
    position: relative;
    cursor: text;
    transition: border 0.2s ease-in-out, background 0.2s ease-in-out,
      box-shadow 0.2s ease-in-out;
    text-indent: 40px;
  }
  .input {
    width: 320px;
    // text-indent:40px;
    // box-sizing: content-box;
    // padding-left:40px;
    overflow: hidden;
  }
  .button {
    width: 320px;
    height: 40px;
    background: #299654;
    border-radius: 2px;
    font-size: 18px;
    font-weight: 400;
    color: #ffffff;
    line-height: 25px;
    letter-spacing: 4px;
    border: none;
  }
  .codeNum {
    width: 74px;
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #838383;
    line-height: 20px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background-color: #ffffff;
  }
  .codeNum:hover {
    color: #299654;
  }
  .codeNumOne {
    width: 74px;
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #838383;
    line-height: 20px;
    // cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background-color: #ffffff;
  }
  .prepend {
    width: 39px;
    height: 39px;
    background-color: rgba(216, 216, 216, 1);
    position: absolute;
    top: 1px;
    left: 1px;
    border-radius: 4px 0 0 4px;
  }
  .iconImg {
    width: 16px;
    height: 26px;
    position: absolute;
    top: 7px;
    left: 12px;
  }
  .iconImg1 {
    width: 24px;
    height: 19px;
    position: absolute;
    top: 12px;
    left: 8px;
  }
}
</style>
